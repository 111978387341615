<div class="flex items-center justify-between md:justify-start md:items-center w-full relative">
  <div class="header-left md:mr-[13.5rem]">
    <div *ngIf="backButton" class="back-img" (click)="redirecToPreviousPage()">
      <img  src="assets/svg/back.svg" alt="back">
    </div>
    <img
      class="h-9 md:h-14"
      src="/assets/svg/liva-logo.svg"
      alt="liva-logo"
    />
  </div>
  <div *ngIf="customerVehicleDetails">
    <div class=" flex flex-col items-center justify-center ">
      <h3 class="text-center font-semibold text-xs md:self-start mb-1 md:text-2xl ">{{customerVehicleDetails.vehicleName}} </h3>
      <div class="customer-details">
          <div class="data-container flex flex-col justify-center items-center md:flex-row md:gap-2 ">
              <p class="text-[0.5rem] md:text-base">VEHICLE VALUE</p>
              <h3 class="text-xs font-semibold mb-[0.5dvh] md:mb-0 md:text-base">AED  <span>{{customerVehicleDetails?.vehicleValue | number}}</span></h3>
          </div>
          <div class="data-container flex flex-col items-center justify-center md:flex-row md:gap-2">
              <p class="text-[0.5rem] md:text-base">POLICY START DATE</p>
              <h3 class="text-xs font-semibold mb-[0.5dvh] md:mb-0 md:text-base ">{{customerVehicleDetails.policyStartDate}}</h3>
          </div>
      </div>
    </div>
  </div>
  <div class=" flex gap-2 md:gap-4 items-center md:ml-auto md:mr-16"  [ngClass]="customerVehicleDetails ? 'self-start md:self-auto':''">
    <!-- <img class="share" src="/assets/svg/share.svg" [ngClass]="hideShareIcon ? 'hidden':''" alt="" (click)="copy()"/> -->
    <!-- <img class="share" src="/assets/svg/user1.svg" alt="user" [ngClass]="hideProfileIcon ? 'hidden':''" (click)="changeProfileDropdownStatus()" /> -->
    <!-- <button *ngIf="quoteResetButton" class="text-xs bg-[#FF6B00] text-white rounded-full px-1" (click)="ResetQuote()">Quote Reset</button> -->
  </div>
  <!-- dropdown -->
   <div *ngIf="profileDropdownStatus" class="bg-white border  shadow  text-[#FF6B00] rounded-lg min-h-[10dvh] p-3 absolute top-9 right-0 flex justify-center items-center">
    <h1 class="text-center text-sm" (click)="redirectToResetPassword()">Change Password</h1>
   </div>
</div>
