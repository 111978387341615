import { Component, OnInit } from '@angular/core';
import { DrawerService } from '../../services/drawer/drawer.service';
import { ApiService } from 'src/app/core/api/api-service/api.service';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent implements OnInit {

  constructor(
    private drawerService: DrawerService  ) {

  }
  // Comprehensive-Agency
  listOfPlans = {
    "Comprehensive-Agency": [
      "Loss or Damage to the Insured Vehicle",
      "Third Party Liability for Bodily Injury",
      "Third Party Liability for Property Damage up to AED 3,500,000",
      "Territory Coverage Extended to Oman",
      "Coverage when Driving Another Car",
      "Ambulance Cost up to AED 6,770",
      "Perils Of Nature, Riot, Strike & Civil Commotion",
      "Windscreen Coverage",
      "Loss of Personal Items up to AED 5,000",
      "Emergency Medical Expenses up to AED 3,500",
      "Personal Injury Cover up to AED 20,000",
      "Replacement of Locks up to AED 5,000",
      "Motor Garage and/or Valet Parking",
      "No Claims Discount",
      "Emergency Repairs up to AED 1,000",
      "Agency repairs",
      "New Vehicle Replacement up to 6 months",
      "Taxi Fares up to AED 300",
    ],
    //  // "Third Party Liability to Family Members and Passengers",
    "Comprehensive": [
      "Loss or Damage to the Insured Vehicle",
      "Third Party Liability for Bodily Injury",
      "Third Party Liability for Property Damage up to AED 3,500,000",
      "Territory Coverage Extended to Oman",
      "Coverage when Driving Another Car",
      "Ambulance Cost up to AED 6,770",
      "Perils Of Nature, Riot, Strike & Civil Commotion",
      "Windscreen Coverage",
      "Loss of Personal Items up to AED 5,000",
      "Emergency Medical Expenses up to AED 3,500",
      "Personal Injury Cover up to AED 20,000",
      "Replacement of Locks up to AED 5,000",
      "Motor Garage and/or Valet Parking",
      "No Claims Discount",
      "Emergency Repairs up to AED 1,000",
      "Liva Approved Garages (Non-Agency)",
      "New Vehicle Replacement up to 6 months",
      "Taxi Fares up to AED 300",
    ],
    "Third Party": [
      "Third Party Liability for Bodily Injury",
      "Third Party Liability for Property Damage up to AED 3,500,000",
      "Territory Coverage Extended to Oman",
      "Ambulance Cost up to AED 6,770",

    ]
  }

  listOfPolicies: any

  drawerStatus: boolean
  planName: string = 'New Plan'


  async ngOnInit(): Promise<void> {
    this.drawerService.drawerStatus$.subscribe((val) =>
      this.drawerStatus = val
    )
    this.drawerService.listOfPolicies$.subscribe((val) => {
      this.listOfPolicies = JSON.parse(val['topups'])
      this.planName = val['plan']
    }
    )
    
  }

  changeDrawerStatus() {
    this.drawerService.changeDrawerStatus()
   
  }
}
