import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/api/api-service/api.service';
import { API_ENDPOINTS } from '../../api-endpoints';
import { QUOTEENQUIRYID, PREVIOUSWORKFLOW, CURRENTWORKFLOW, VERSION } from '../../interface/master-interface';
import { SessionService } from '../session-service/session.service';

@Injectable({
  providedIn: 'root'
})
export class QuoteSummaryService {


  constructor(private  apiService:ApiService,private sessionStorage:SessionService) { }

  async getquoteDetails() {
    try {
      let apiResponse = await this.apiService.getApiCall(`${API_ENDPOINTS.quoteDetails}`, {});
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async getQuotationNumber(body){
    try{
      let apiResponse = await this.apiService.postApiCall(`${API_ENDPOINTS.quoteDetails}/${API_ENDPOINTS.getQuotatonNumber}`,body)
      return apiResponse
    }catch(exception){
      throw exception
    }
  }

  async getquoteFlowDataMapping(ps:{pageStatus:string}) {
    try {
      let apiResponse = await this.apiService.getApiCall(`${API_ENDPOINTS.quoteDetails}/${API_ENDPOINTS.quoteFlowDataMapping}`,ps );
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async getCustomerLastWorkflow() {
    try {
      let apiResponse = await this.apiService.getApiCall(`${API_ENDPOINTS.createQuote}/${API_ENDPOINTS.getLastWorkflow}`, {});
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async getCustomerLastWorkflowInOurObject(flowdata) {
    try {
      let results = await this.getCustomerLastWorkflow();
      console.log("--results",results)
      if (results.response.length > 0) {
        let workFlowObjects = results.response;
        let flows = flowdata.filter(flowObj => { return workFlowObjects[0].currentWorkflow.trim().toLowerCase() == flowObj.flowName });
        console.log("-------flows---------------",flows)
        this.sessionStorage.set(QUOTEENQUIRYID, workFlowObjects[0].quoteEnquiryId);
        this.sessionStorage.set(VERSION, workFlowObjects[0].version);
        this.sessionStorage.set(PREVIOUSWORKFLOW, workFlowObjects[0].previousWorkflow);
        this.sessionStorage.set(CURRENTWORKFLOW, workFlowObjects[0].currentWorkflow);
        return flows;
      }
    } catch (exception) {

    }
  }

  async getSelectedProduct(){
    try{
      const result = await this.apiService.getApiCall(`${API_ENDPOINTS.createQuote}/${API_ENDPOINTS.quoteSummary}`)
      return result.response[0]
    }
    catch(exception){
      return exception
    }
  }

  async getChassisNumber(chassisNumber){
    try{
      const result = await this.apiService.getApiCall(`${API_ENDPOINTS.createQuote}/${API_ENDPOINTS.xaData}?chassisNumber=${chassisNumber}`)
      return result;
    }
    catch(exception){
      return exception
    }
  }

  async getTermsCondition (){
    try {
      let apiResponse = await this.apiService.getApiCall(`${API_ENDPOINTS.createQuote}/${API_ENDPOINTS.termsCondition}`, {});
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async getQuotation(params: { customerId: string, quoteEnquiryId: number }) {
    try {
      const response = await this.apiService.postApiCall(`${API_ENDPOINTS.quoteDetails}/${API_ENDPOINTS.retriveQuotation}`, params);
      return response;
    } catch (exception: any) {
      throw exception;
    }
  };
}
