import { Component } from '@angular/core';

@Component({
  selector: 'app-need-support',
  templateUrl: './need-support.component.html',
  styleUrls: ['./need-support.component.scss']
})
export class NeedSupportComponent {

  showSupport: boolean = false
  supportEmail:string = 'insurance.ae@livainsurance.com'

  changeSupportStatus(){
    this.showSupport = !this.showSupport
  }

}
