<div class="flex flex-col justify-center items-center mt-6 md:mt-3 mb-3 md:hidden gap-1">
    <img src="assets/svg/aura-logo.svg" alt="" class="h-7"> 
    <p class="text-[#999999] text-[0.625rem]">Powered by Aura</p>
</div>

<!-- <div class="hidden md:h-12 md:mt-9 md:flex md:justify-around md:items-center md:w-full md:bg-[#E5E5E5] text-[#999999]">
    <div class="max-w-[896px] md:px-4 md:flex md:justify-between md:items-center w-full">
        <div class="flex  gap-3 items-center">
            <p>Follow Liva on social</p>
            <div class="flex gap-4">
                <img class="h-4" src="assets/svg/facebook.svg" alt="">
                <img class="h-4" src="assets/svg/instagram.svg" alt="">
                <img class="h-4" src="assets/svg/linkedin.svg" alt="">
            </div>
        </div>
        <div class="flex gap-2 items-center">
            <img src="assets/svg/aura-logo.svg" alt="" class="h-6">
            <p>Powered by Aura</p>
        </div>
    </div>
</div> -->

<div class="hidden md:h-12 md:mt-9 md:flex md:justify-center md:gap-28 md:items-center md:w-full md:bg-[#E5E5E5] text-[#999999]">
        <div class="flex  gap-3 items-center">
            <p>Follow Liva on social</p>
            <div class="flex gap-4">
                <img class="h-4" src="assets/svg/facebook.svg" alt="">
                <img class="h-4" src="assets/svg/instagram.svg" alt="">
                <img class="h-4" src="assets/svg/linkedin.svg" alt="">
            </div>
        </div>
        <!-- <div class="flex  gap-3 items-center">
            <p>Follow Liva on social</p>
            <div class="flex gap-4">
                <img class="h-4" src="assets/svg/facebook.svg" alt="">
                <img class="h-4" src="assets/svg/instagram.svg" alt="">
                <img class="h-4" src="assets/svg/linkedin.svg" alt="">
            </div>
        </div> -->
        <div class="flex gap-2 items-center pr-[68px] 4xl:pr-[84px]">
            <img src="assets/svg/aura-logo.svg" alt="" class="h-6">
            <p>Powered by Aura</p>
        </div>
 </div>
