import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/api/api-service/api.service';
import { API_ENDPOINTS } from '../../api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class GenerateTokenService {

  constructor(
    private apiService:ApiService
  ) { }

  async generateToken(body){
    try {
      const result = await this.apiService.postApiCall(`${API_ENDPOINTS.salik}/${API_ENDPOINTS.generateToken}`,body)
      return result
    } catch (error) {
      throw error
    }
  }
}
