import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CURRENTWORKFLOW, PREVIOUSWORKFLOW } from 'src/app/shared/interface/master-interface';
import { flowBasedRouting } from 'src/app/shared/mock-data';
import { CreateQuoteService } from 'src/app/shared/services/create-quote/create-quote.service';
import { QuoteSummaryService } from 'src/app/shared/services/quote-summary/quote-summary.service';
import { SessionService } from 'src/app/shared/services/session-service/session.service';
import { CUSTOMERUUVID } from 'src/app/shared/session-variables';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  showRetryButton: boolean = false
  currentWorkFlow: string
  flowdata = flowBasedRouting
  previousWorkFlow: string;
  isButtonDisabled: boolean = false
  customerUuvid: string;
  retryRemainingTime: number = 0
  contactEmail: any = 'insurance.ae@livainsurance.com'
  debounceRetryCallable!: (...args: any[]) => void


  constructor(
    private sessionStorage: SessionService,
    private createQuoteService: CreateQuoteService,
    private quoteSumaryService: QuoteSummaryService,
    private router: Router
  ){
    this.debounceRetryCallable = this.debounceRetry(this.createQuoteAPI,60000)
  }

  ngOnInit(): void {
    this.getSessionValues();
  }

  getSessionValues(){
    this.previousWorkFlow = this.sessionStorage.get(PREVIOUSWORKFLOW);
    this.currentWorkFlow = this.sessionStorage.get(CURRENTWORKFLOW);
    this.customerUuvid = this.sessionStorage.get(CUSTOMERUUVID)

        if(this.previousWorkFlow == 'url_generated' &&   this.currentWorkFlow == 'quote_failed'){
          this.showRetryButton = true
    }
  }

  retryCreateQuote(){
    if(this.retryRemainingTime == 0){
      this.retryRemainingTime = 60
    }
    if(this.debounceRetryCallable){
      this.debounceRetryCallable()
    }
    this.decreaseRetryTime()
  }

  decreaseRetryTime(){
    setInterval(()=>{
      if(this.retryRemainingTime > 0){
        this.retryRemainingTime =  this.retryRemainingTime - 1
      }
    },1000)
  }



  debounceRetry(func,cooldown){
    return function(...args){
      //  Check if the timer is still on and button is not clickable
      if(this.isButtonDisabled){
        return;
      }

      //after running the function disable the button

      func.apply(this,args)
      this.isButtonDisabled = true

      //re-enable the button after the cooldown time
      setTimeout(()=>{
        this.isButtonDisabled = false
      },cooldown)
    }
  }

  async createQuoteAPI() {
    // this.buttonEnable=false
    try {
      let params = {
        currentWorkFlow: 'url_generated'
      }
      let response = await this.createQuoteService.creteQuote(params);

      // if(response?.statusCode===400){


      // }else if(response?.statusCode===201){
      //   //  this.buttonEnable=true
      // }
      await this.getCustomerLastWorkflow()
      this.getSessionValues();
    } catch (exception) {
      // this.toaster.error(exception.message)
    }
  }
  async getCustomerLastWorkflow() {
    try {
      let getFLows = await this.quoteSumaryService.getCustomerLastWorkflowInOurObject(this.flowdata);

      this.router.navigateByUrl(`${this.customerUuvid}/${getFLows[0].route}`)
    } catch (exception) {
      // this.toaster.error('Something went wrong while redirecting')
    }
  }

  
}
