<div class="flex flex-col justify-between items-center h-screen ">
    <div class="w-full">
      <!-- <img
      class="liva-logo"
      src="assets/svg/liva-logo.svg"
      alt="liva-logo"
    /> -->
        <!-- <app-header [hideShareIcon]="true" [hideProfileIcon]="true"></app-header> -->
        <app-header-v2  [pageName]="'otp'"></app-header-v2>
    </div>
    <div class="flex-1 flex-col flex justify-center items-center w-full p-4">
        <div class="bg-[#FFE1CC] p-6 max-w-lg mx-auto rounded-lg text-center shadow-lg mt-10 ">

            <h1 class=" text-[#FF6B00] text-2xl mb-2 font-semibold">Hello {{customerName}}</h1>
            <p class=" text-[#666666] text-sm mb-4">Please enter the OTP sent to {{obscuredEmail}}
            </p>
            <div class="mb-4">
                <ng-otp-input [config]="config" (onInputChange)="setOtp($event)"></ng-otp-input>
            </div>
            <button 
            class=" bg-[#FF6B00] text-white py-2 px-4 rounded-md hover:bg-[#FF5500] transition-colors duration-300"
            (click)="submitOtp()"
            >Submit</button>
            <p class=" text-[#666666] text-sm mt-4">Didn’t receive the OTP? 
                <span  class=" text-[#FF6B00] font-semibold hover:underline" (click)="resendOtp()">Resend OTP</span>
              </p>
        </div>
    </div>
    <div class="w-full">
        <!-- <app-footer></app-footer> -->
        <app-footer-v2 class="w-full"></app-footer-v2>
    </div>
</div>

