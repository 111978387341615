import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';
import { API_ENDPOINTS } from 'src/app/shared/api-endpoints';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private apiService:ApiService
  ) { }

  async getCustomerDetails(){
    try {
      const result = await this.apiService.getApiCall(`${API_ENDPOINTS.customerDetail}`)
      return result;
    } catch (error) {
      throw error
    }
  }
}
