import { Injectable } from '@angular/core';
import { QuoteSummaryService } from '../quote-summary/quote-summary.service';
import { Router } from '@angular/router';
import { flowBasedRouting } from '../../mock-data';
import { CURRENTWORKFLOW, PREVIOUSWORKFLOW, QUOTEENQUIRYID } from '../../interface/master-interface';
import { CreateQuoteService } from '../create-quote/create-quote.service';
import { SessionService } from '../session-service/session.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  quoteEnquiryId: number;
  previousWorkFlow: string;
  currentWorkflow: string;
  version: number;

  constructor(private quoteSumaryService: QuoteSummaryService, private router: Router,private createQuoteService: CreateQuoteService,private sessionStorage: SessionService) { }

  async getCustomerLastWorkflow(customerUuvid) {
    try {
      let flowdata = flowBasedRouting;
      let getFLows = await this.quoteSumaryService.getCustomerLastWorkflowInOurObject(flowdata);
      this.router.navigateByUrl(`${customerUuvid}/${getFLows[0].route}`);
    } catch (exception) {

    }
  }

  async getXADataValidation(customerUuvid) {
    try {
      let flowdata = flowBasedRouting;
      let getFLows = await this.quoteSumaryService.getCustomerLastWorkflowInOurObject(flowdata);
      this.router.navigateByUrl(`${customerUuvid}/${getFLows[0].route}`);
    } catch (exception) {

    }
  }

  async isQuoteFailedStatus(flowdata,currentWorkflow,quoteEnquiryId,token,previousWorkFlow,version,customerUuvid){

  let flows = flowdata.filter(flowObj => { return currentWorkflow.trim().toLowerCase() == flowObj.flowName.trim().toLowerCase() });
  if(currentWorkflow==="quote_failed"){
    let lastSuccessId=await this.createQuoteService.errorRedirectQuote(customerUuvid)
    this.sessionStorage.set(QUOTEENQUIRYID,lastSuccessId.response.quoteEnquiryId);
    this.sessionStorage.set(PREVIOUSWORKFLOW,lastSuccessId.response.lastSuccessWorkFlow.previousWorkFlow);
    this.sessionStorage.set(CURRENTWORKFLOW,lastSuccessId.response.lastSuccessWorkFlow.currentWorkFlow);
    this.quoteEnquiryId = this.sessionStorage.get(QUOTEENQUIRYID)
    this.previousWorkFlow = this.sessionStorage.get(PREVIOUSWORKFLOW);
    this.currentWorkflow = this.sessionStorage.get(CURRENTWORKFLOW);
    await this.updateWorkFlowForFailedBackButton();
    await this.getCustomerLastWorkflow(customerUuvid);
  }
  else if(currentWorkflow==="payment_done" || currentWorkflow==="document_uploaded" ||currentWorkflow==="policy_generated") {
    await this.getCustomerLastWorkflow(customerUuvid);
  }
    // else if(currentWorkflow==="payment_failed"){
    //   this.sessionStorage.set(PREVIOUSWORKFLOW,"selected_product");
    //   this.sessionStorage.set(CURRENTWORKFLOW,"quote_confirmed");
    //   this.quoteEnquiryId = this.sessionStorage.get(QUOTEENQUIRYID)
    //   this.previousWorkFlow = this.sessionStorage.get(PREVIOUSWORKFLOW);
    //   this.currentWorkflow = this.sessionStorage.get(CURRENTWORKFLOW);
    //   await this.updateWorkFlow();
    //   await this.getCustomerLastWorkflow(customerUuvid);
    //   this.router.navigateByUrl(`${customerUuvid}/${flows[0].backbuttonRoute}`)
    //   }
    }

    async updateWorkFlowForFailedBackButton() {
      try {
        let workFlowDetail = {
          quoteEnquiryId: this.quoteEnquiryId,
          previousQuoteStatus: this.previousWorkFlow, //For back button the flow is interchanged so previousflow=currentFlow ,currentFlow=PreviousFlow
          currentQuoteStatus: this.currentWorkflow,
          version: this.version
        }
        const response = await this.createQuoteService.updateWorkFlowStatus(workFlowDetail)
      } catch (error) {
      }
    }

    async updateWorkFlow() {
      try {
        let workFlowDetail = {
          quoteEnquiryId: this.quoteEnquiryId,
          previousQuoteStatus: this.currentWorkflow, //For back button the flow is interchanged so previousflow=currentFlow ,currentFlow=PreviousFlow
          currentQuoteStatus: "quote_edited",
          version: this.version
        }
        // return
        const response = await this.createQuoteService.updateWorkFlowStatus(workFlowDetail)
      } catch (error) {
      }
    }
}
