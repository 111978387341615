<div class=" flex flex-col justify-between h-screen">
    <div>
        <app-header-v2 [pageName] = '"error-page"' class="px-8"></app-header-v2>
    </div>
    <div class="flex flex-col items-center justify-center p-4 ">
         <div class="flex flex-col justify-center items-center gap-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51">
                <g id="Group_609" data-name="Group 609" transform="translate(-186 -350.715)">
                  <circle id="Ellipse_125" data-name="Ellipse 125" cx="25.5" cy="25.5" r="25.5" transform="translate(186 350.715)" fill="#ff6900"/>
                  <g id="Group_608" data-name="Group 608" transform="translate(211.357 364.99)">
                    <line id="Line_52" data-name="Line 52" transform="translate(-0.357 23.725)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
                    <line id="Line_51" data-name="Line 51" y2="18" transform="translate(-0.357 -0.275)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="3"/>
                  </g>
                </g>
              </svg>               
              <!-- <p class="text-[1.688rem] text-bold text-[#FF6900]">404</p>   -->
              <div class="md:text-[1.625rem] font-light text-center">Page Not Found</div>
        
         </div>
            </div>
    <div>
    </div>
</div>