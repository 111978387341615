import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownStatusService } from '../../services/dorpdown/dropdown-status.service';
import { QuoteSummaryService } from '../../services/quote-summary/quote-summary.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-dynamic-select-v2',
  templateUrl: './dynamic-select-v2.component.html',
  styleUrls: ['./dynamic-select-v2.component.scss']
})
export class DynamicSelectV2Component implements OnInit {
  @Output() emitChasssiNumberStatus: EventEmitter<any> = new EventEmitter();
  @Output() enableChassisEdit = false;
  @Input() title: string;
  @Input() selected: any;
  @Input() options: any;
  @Input() type: string;
  @Input() id: string;
  @Input() masterKey: string;
  @Input() index: number;
  @Input() dbField: string;
  @Input() policyExpiryDate: string;
  @Input() vehicleSpecification: any;
  @Input() showMakeModel: any;
  @Input() ncbYearsVisibleStatus: any;  
  @Input() insuranceExpire: any;
  @Input() hideSumInsuredSlider : boolean
  @Input() rangeValue : number
  @Input() minSumInsured : number
  @Input() maxSumInsured : number
  @Input() sliderOptions: Options
  oldChassisNumber: any;

  @Output() errorStatus: EventEmitter<{ errorStatus: boolean }> = new EventEmitter<{ errorStatus: boolean }>()
  @Output() mobileNumberError: EventEmitter<{ mobileNumberError: boolean }> = new EventEmitter<{ mobileNumberError: boolean }>();
  @Output() emailLengthCheck: EventEmitter<{ emailLengthCheck: boolean }> = new EventEmitter<{ emailLengthCheck: boolean }>();
  @Output() emptyEmail: EventEmitter<{ empty: boolean }> = new EventEmitter<{ empty: boolean }>()
  @Output() ncbYearsSelectionStatus: EventEmitter<{ selected: boolean }> = new EventEmitter<{ selected: boolean }>()
  @Output() selectedValuePair: EventEmitter<{ label: string, value: string, dbField: string, code: number, masterKey: string, index: number }> = new EventEmitter<{ label: string, value: string, dbField: string, code: number, masterKey: string, index: number }>()
  @Output() updatedSliderPremium: EventEmitter<number> = new EventEmitter<number>

  showDropdown: boolean = false
  searchedItmes: Array<any>
  searchInputValue: string
  changeParaToInput: boolean = false
  emailValue: string
  inputDateValue: Date
  dateLimitValue: Date | string
  inputValue: string
  checkedValue: string
  chassisNumberLabel: string
  chassisNumberValue: string
  ncbYearValue: any
  ncbYes: boolean = false
  ncbNo: boolean = false
  maxDate: any 

  sampleError:boolean=false


  public errorObject = {
    emailEmptyError: false,
    emailWrongFormatError: false,
    ncbYearsEmptyError: false,
    ncbYearsWronFormatError: false,
    mobileNumberWrongFormatError: false,
    policyStartDateMinError: false,
    policyStartDateMaxError: false,
    chasisNumberLengthError: false,
    chasisNumberInvalidError: false,
    firstNameLengthError: false,
    lastNameLengthError: false,
    yearOfManufactureError: false,
    plateNumberPatternError: false,
    dateOfBirthError: false,
  }
  
  public mobileNumberValidation = {
    mobileNumberEmptyError: false,
    mobileNumberlength: false,
    emailLengthCheck:false
  }

  constructor(
    private dropdownStatusService: DropdownStatusService,
    private quoteSummaryService: QuoteSummaryService,
    private spinner: NgxSpinnerService
  ) {

  }

  ngOnInit(): void {
    if(this.ncbYearsVisibleStatus === 0){
      this.ncbNo = false
      this.ncbYes = false
    }
    this.searchedItmes = this.options
    this.setValuesAsPerType(this.type)
    
    this.dropdownStatusService.dropdownStatus$.subscribe((id) => {
      this.showDropdown = (this.id === id)
    })
  }

 
  setValuesAsPerType(type: string) {
    if (type === 'date') {
      // if(this.dbField == 'issueDate' || 'eidExpiryDate'){
      // //  this.selected=  this.formatDate(this.selected)
      // }
      this.inputDateValue = this.selected?.slice(0, 10)
      if (this.dbField == 'effectiveDate') {

        const today = new Date()
        // console.log(this.selected,today.toISOString().slice(0,10))
        this.dateLimitValue = today.toISOString().slice(0,10)
        const newDay = new Date()
        newDay.setDate(newDay.getDate() + 30)
        const dateExpiry = this.insuranceExpire;
        const [day, month, year] = dateExpiry.split('-');
        this.maxDate = `${year}-${month}-${day}`;
        // this.dateLimitValue = this.selected.slice(0, 10)
      }else if(this.dbField == 'eidExpiryDate'){
        const today = new Date();
        this.dateLimitValue = today.toISOString().split('T')[0]; 
      }
    }
    else if (type == 'email') {
      this.emailValue = this.selected
      if( this.selected === null || this.selected.trim()===''){
        this.emptyEmail.emit({empty:true})
      }else{
        this.emptyEmail.emit({empty:false})
      }
    } else if (type == 'static' && this.dbField == "dob") {
      this.selected = this.selected.split("-").reverse().join("-")
    } else if (this.dbField == 'dob') {
      this.inputDateValue = this.selected.slice(0, 10)
      this.dateLimitValue = this.ageGreaterThanEighteenYears()
    }else if(type == 'static' && this.dbField === 'dateOfRegistration'){
      this.selected = this.selected.split(' ')[0].split('-').reverse().join('-')
    } else if (type === 'radio') {
     
      this.ncbYearValue = this.selected
      if(this.ncbYearsVisibleStatus=== 1){
        this.changeNcbSelection(this.selected)
      }
    }
    else {
      this.inputValue = this.selected
    }
  }

  sendRangeSliderValue(event){
    this.updatedSliderPremium.emit(event)
  }

  // clickCheck($event){
  //   console.log($event)
  // }

  formatDate(date: string) {
    const testDate = new Date(date)
    const year = testDate.getFullYear()
    const month = String(testDate.getMonth() + 1).padStart(2, '0')
    const day = String(testDate.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  changeDropdownStatus(id: string) {
    this.showDropdown ? this.dropdownStatusService.notifyDropdownStatus('') : this.dropdownStatusService.notifyDropdownStatus(id)
  }

  dropDownItemSelected(label: string, value: any, code?: number) {
    this.selected = value
    this.changeDropdownStatus('')
    this.searchInputValue = ''
    this.searchedItmes = this.options
    this.sendSelectedItems(label, value, code)
  }

  showSearchItem() {
    const newItemsArray = []
    this.options.filter((item) => {
      if (item.description.toLowerCase().includes(this.searchInputValue.toLowerCase())) {
        newItemsArray.push(item)
      }
    }
    )
    this.searchedItmes = [...newItemsArray]
  }

  sendValue(event?: any, label?: string, value?: any, code?: number) {
    this.changeParaToInput = !this.changeParaToInput
    if (label === 'Policy Start Date') {
      this.checkPolicyStartDateError(event)
    }
    if (label === 'Email') {
      this.checkEmailError(event)
      this.emptyEmail.emit({empty:false})
    }
    if (label === 'Mobile Number') {
    }
    if (label === 'Chassis Number') {
      this.chassisNumberLabel = label
      this.chassisNumberValue = value
      this.checkChassisNumberError(event)
    }
    if (label === 'First Name') {
      this.checkFirstNameError(event)
    }
    if (label === 'Last Name') {
      this.checkLastNameError(event)
    }
    if (label === 'Year Of Manufacture') {
      this.checkYearOfManufactureError(event)
    }
    if (label === 'Plate Number') {
      this.checkPlateNumberError(event)
    }
    if (label == 'Date of Birth') {
      this.checkDateOfBirthError(event)
    } 
    if (label && label !== 'Chassis Number') {
      if (this.dbField === 'ncbYears') {
        // this.errorObject.ncbYearsEmptyError = false
      // this.ncbYearsSelectionStatus.emit({selected:true})
      //   if(value === 0){
      //     this.ncbYes = true
      //     this.ncbNo = false
      //   }else{
      //     this.ncbNo = true
      //     this.ncbYes = false
      //   }
      //   this.ncbYearValue = value

      
      
      this.changeNcbSelection(value)
      }
      this.sendSelectedItems(label, value, code)
      if(this.dbField !== 'ncbYears'){
        this.sendErrorStatus()
      }
    }
  }

  changeNcbSelection(value){
    this.ncbYearsSelectionStatus.emit({selected:true})
    console.log( this.ncbYearsSelectionStatus.emit({selected:true}),"---------------------");
    if(value === 0){
      this.ncbYes = true
      this.ncbNo = false
    }else{
      this.ncbNo = true
      this.ncbYes = false
    }
    this.ncbYearValue = value
    this.ncbYearsVisibleStatus=1
    console.log( this.ncbYearsVisibleStatus, this.ncbYearValue );
  }

  sendSelectedItems(label: string, value: string, code?: number) {
    this.selectedValuePair.emit({ label, value, dbField: this.dbField, code, masterKey: this.masterKey, index: this.index })
  }

  disableDate() {
    return false
  }

  ageGreaterThanEighteenYears() {
    const today = new Date()
    const date18YearsAgo = new Date()
    date18YearsAgo.setFullYear(today.getFullYear() - 18)
    return date18YearsAgo.toISOString().slice(0, 10)
  }

  checkDateOfBirthError(event) {
    if (event.target.value > event.target.max) {
      this.errorObject.dateOfBirthError = true
    } else {
      this.errorObject.dateOfBirthError = false
    }
  }

  checkPolicyStartDateError(event) {
    const [day, month, year] = this.insuranceExpire.split('-');
    const insuranceExpireDate = new Date(year, month - 1, day);
    const today = new Date();
    if(insuranceExpireDate < today) {
      this.errorObject.policyStartDateMinError = true
      this.errorObject.policyStartDateMaxError = false
    }
    if (event.target.value < event.target.min) {
      this.errorObject.policyStartDateMinError = true
      this.errorObject.policyStartDateMaxError = false
    } else if(event.target.value > event.target.max){
      this.errorObject.policyStartDateMaxError = true
      this.errorObject.policyStartDateMinError = false
    }
    else {
      this.errorObject.policyStartDateMinError = false
      this.errorObject.policyStartDateMaxError = false
    }
  }
  checkEmailError(event) {
    const regx = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    const pattern = new RegExp(regx)

    if (event?.target?.value?.trim() === '') {
      this.errorObject.emailEmptyError = true
      this.errorObject.emailWrongFormatError = false;  
    } else if (!pattern.test(event?.target?.value)) {
      this.errorObject.emailWrongFormatError = true;
      this.errorObject.emailEmptyError = false
    }
    else {
      this.errorObject.emailWrongFormatError = false;
      this.errorObject.emailEmptyError = false;
    }
  }


  checkAllowedInput(event) {
    // The allowed key sare 'Backspace', 'Tab', 'Enter', 'ArrowRight', 'ArrowLeft','Delete', 0-9, Numpad0 - Numpad9
    const allowedKeys = [0,8,9,13,37,39,46,96,97,98,99,100,101,102,103,104,105]
    // if ((event.which != 8 && event.which != 0 && event.which < 48) || event.which > 57) {
      if ((!allowedKeys.includes(event.which) && event.which < 48) || (event.which > 57 && (event.which < 96 || event.which >105 ) )) {
      event.preventDefault();
    }
    const regex = new RegExp(/^\D*(\d\D*){10}$/);
    const valid = regex.test(event.target.value.toString());
    // if (event.target.value.trim() === '') {
    //   this.errorObject.mobileNumberEmptyError = true
    //   this.errorObject.mobileNumberWrongFormatError = false
    // }
    //  else if (!valid) {
    //   console.log("--enter else if")
    //   this.errorObject.mobileNumberWrongFormatError = true
    //   this.errorObject.mobileNumberEmptyError = false
    // }
    // else {
    //   this.errorObject.mobileNumberEmptyError = false
    //   this.errorObject.mobileNumberWrongFormatError = false
    // }
  }

  checkLengthOfNumber(event: any, type: string) {
    const inputLength = event.target.value.length;
    if(type.trim().toLowerCase() == "number") {
    if (inputLength === 0) {
      this.mobileNumberValidation.mobileNumberEmptyError = true
      this.errorObject.mobileNumberWrongFormatError = false
      this.mobileNumberValidation.mobileNumberlength = false;
      this.mobileNumberError.emit({ mobileNumberError: true });
    } else if(inputLength > 16) {
      this.mobileNumberValidation.mobileNumberlength = true;
      this.mobileNumberValidation.mobileNumberEmptyError = false;
      this.mobileNumberError.emit({ mobileNumberError: true });
    } else {
      this.mobileNumberValidation.mobileNumberlength = false;
      this.mobileNumberValidation.mobileNumberEmptyError = false
      this.mobileNumberError.emit({ mobileNumberError: false });
    }
  } else {
    if(inputLength > 60) {
      this.mobileNumberValidation.emailLengthCheck = true;
      this.emailLengthCheck.emit({ emailLengthCheck: true });
    } else {
      this.mobileNumberValidation.emailLengthCheck = false;
      this.emailLengthCheck.emit({ emailLengthCheck: false });
    }
  }
  }

  checkChassisNumberError(event) {
    if (event.target.value.trim().length == 17) {
      this.errorObject.chasisNumberLengthError = false;
    } else {
      this.errorObject.chasisNumberLengthError = true;
      this.errorObject.chasisNumberInvalidError = false;
    }
  }

  checkFirstNameError(event) {
    if (event.target.value.trim().length < 1) {
      this.errorObject.firstNameLengthError = true
    } else {
      this.errorObject.firstNameLengthError = false
    }
  }

  checkLastNameError(event) {
    if (event.target.value.trim().length < 1) {
      this.errorObject.lastNameLengthError = true
    } else {
      this.errorObject.lastNameLengthError = false
    }
  }

  checkYearOfManufactureError(event) {
    const currentYear = new Date().getFullYear()
    if (event.target.value > currentYear) {
      this.errorObject.yearOfManufactureError = true
    } else {
      this.errorObject.yearOfManufactureError = false
    }

  }

  checkPlateNumberError(event) {
    const regx = /^[A-Za-z] [A-Za-z0-9]{5}$/
    const pattern = new RegExp(regx)
    console.log(pattern.test(event.target['value']))
    if (!pattern.test(event.target.value.trim())) {
      this.errorObject.plateNumberPatternError = true
    } else {
      this.errorObject.plateNumberPatternError = false
    }
  }


//--------------------------- EMirated ID filed change--------------------------------------
  formatEmiratesId(value: string): string {
    if (!value) return '';
    return value.replace(/(\d{3})(\d{4})(\d{7})(\d{1})/, '$1-$2-$3-$4');
  }
  unformatEmiratesId(value: string): string {
    // Remove dashes while storing or for other fields 
    return value.replace(/-/g, '');
  }
  

  sendErrorStatus() {
    const values = Object.values(this.errorObject)
    this.errorStatus.emit({ errorStatus: values.includes(true) })
  }

  editChassisNumber() {
    try {
      this.oldChassisNumber = this.inputValue;
      this.enableChassisEdit = true;
      this.emitChasssiNumberStatus.emit(true);
      this.showMakeModel = false;
    } catch (exception) {

    }

  }
  enableCancel() {
    this.enableChassisEdit = false;
    this.emitChasssiNumberStatus.emit(false);
    this.showMakeModel = true;
    this.inputValue = this.oldChassisNumber;
    this.selected = this.oldChassisNumber;

    this.errorObject.chasisNumberLengthError = false;
    this.errorObject.chasisNumberInvalidError = false;
    this.sendErrorStatus()
  }
  async enableTick() {

    try {
      this.spinner.show()
      if (this.inputValue.trim().length == 17) {
        this.errorObject.chasisNumberLengthError = false;
      } else {
        this.errorObject.chasisNumberLengthError = true;
      }
      if (!this.errorObject.chasisNumberLengthError) {
        let response = await this.quoteSummaryService.getChassisNumber(this.inputValue);
        if (!response?.response.length || response?.response[0].hasOwnProperty('Error')) {
          this.errorObject.chasisNumberInvalidError = true
          this.errorObject.chasisNumberLengthError = false;
          return;
        }
        this.errorObject.chasisNumberInvalidError = false
        this.enableChassisEdit = false;
        this.emitChasssiNumberStatus.emit(false);
        this.showMakeModel = true;
        this.vehicleSpecification.make = response.response[0].Make;
        this.vehicleSpecification.model = response.response[0].Model;
        this.vehicleSpecification.variant = response.response[0].ModelSpecification;
        this.vehicleSpecification.year = response.response[0].YearOfManf;
        this.selected = this.inputValue;
        this.sendSelectedItems(this.chassisNumberLabel, this.chassisNumberValue)
      }
    } catch (exception) {

    }
    finally {
      this.spinner.hide()
      this.sendErrorStatus()
    }
  }
}
