export const userCredentials = [
  {
    emailId:'salik@gmail.com',
    password:'Welcome@1',
    role:'salik'
  },
  {
    emailId:'callcenter@gmail.com',
    password:'Welcome@1',
    role:'callCenter'
  }
]


export const quoteDetailPageDetailsMasters = {

  vehicleDetails:[
    {
      id: 1,
      title: 'Car Registration city',
      dbField: 'city',
      selected: '',
      options: [],
      type: 'select'
    },
    {
      id: 2,
      title: 'Make',
      dbField: 'make',
      selected: '',
      options: [],
      type: 'select'
    },
    {
      id: 3,
      title: 'Origin Of The Car',
      dbField: 'city',
      selected: '',
      options: [],
      type: 'select'
    },
    {
      id: 4,
      title: 'Model',
      dbField: 'model',
      selected: '',
      options: [],
      type: 'select'

    },
    {
      id: 5,
      title: 'Variant',
      dbField: 'modelSpecification',
      selected: '',
      options: [],
      type: 'select'
    },
    {
      id: 6,
      title: 'Year',
      selected: '',
      dbField: 'yearOfManufacture',
      options: [],
      type: 'select'
    }

  ],
  driverDetails:[
    {
      id: 1,
      title: 'Date of Birth',
      selected: '',
      dbField: 'driverDob',
      type: 'static'
    },
    // {
    //   id: 2,
    //   title: 'Nationality',
    //   selected: 'Germany',
    //   options: [],
    //   type: 'select'
    // },
    {
      id: 2,
      title: 'UAE Driving Experience',
      selected: '',
      dbField: 'driverExperience',
      type: 'static'
    },
  ]
};

export const vehicleDetailsForSummary = [
  {
    title: 'Plate Number',
    value: ''
  },
  {
    title: 'Year Of Manufacture',
    value: ''
  },
  {
    title: 'Make',
    value: ''
  },
  {
    title: 'Model',
    value: ''
  },
  {
    title: 'Insured Value',
    value: ''
  },
  {
    title: 'Policy start date',
    value: ''
  },
  {
    title: 'Policy end date',
    value: ''
  },

]



export const flowBasedRouting =[
  {'flowName':'url_generated',route:'',backbuttonRoute:""},
  {'flowName':'quote_issued',route:'quote-generated',backbuttonRoute:""},
  {'flowName':'quote_generated',route:'quote-generated',backbuttonRoute:""},
  {'flowName':'quote_failed',route:'error-page',backbuttonRoute:""},
  // {'flowName':'selected_product',route:'quote-details'},
  {'flowName':'selected_product',route:'confirm-details',backbuttonRoute:"quote-generated"},
  // {'flowName':'selected_product',route:'edit-details',backbuttonRoute:"quote-generated"},
  {'flowName':'quote_edited',route:'edit-details',backbuttonRoute:"quote-generated"},
  {'flowName':'edit_quote_updated',route:'quote-summary',backbuttonRoute:"confirm-details"},
  {'flowName':'reset_quote_updated',route:'quote-summary',backbuttonRoute:"confirm-details"},
  // {'flowName':'edit_quote_updated',route:'quote-generated',backbuttonRoute:""},

  {'flowName':'review_customer_details',route:'quote-generated',backbuttonRoute:""},
  {'flowName':'summary_update',route:'quote-summary',backbuttonRoute:"confirm-details"},
  {'flowName':'payment_done',route:'document-upload',backbuttonRoute:""},
  {'flowName':'payment_started',route:'quote-summary',backbuttonRoute:"confirm-details"},
  {'flowName':'payment_failed',route:'quote-summary',backbuttonRoute:"confirm-details"},
  {'flowName':'lexis_nexis_failed',route:'quote-summary',backbuttonRoute:"confirm-details"},

  {'flowName':'quote_confirmed',route:'quote-summary',backbuttonRoute:'confirm-details'},
  // {'flowName':'payment_failed',route:'quote-generated'},
  // {'flowName':'payment_started',route:'quote-generated'},
  // {'flowName':'payment_done',route:'quote-generated'},
  {'flowName':'policy_generated',route:'view-policy',backbuttonRoute:""},
  {'flowName':'policy_failed',route:'error-page',backbuttonRoute:"document-submitted"},
  {'flowName':'document_uploaded',route:'document-submitted',backbuttonRoute:""},
  {'flowName':'upload_failed',route:'error-page',backbuttonRoute:"document-upload"},
  {'flowName':'policy_quote_updated',route:'document-submitted',backbuttonRoute:""},
  {'flowName':'policy_quote_failed',route:'document-submitted',backbuttonRoute:"document-submitted"},

  // {'flowName':'payment_pending',route:'payment-wait',backbuttonRoute:""},

]
