import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { CommonService } from 'src/app/shared/services/common-service/common-service.service';
import { SessionService } from 'src/app/shared/services/session-service/session.service';
import { CUSTOMERUSERID, CUSTOMERUUVID, TOKEN, EMAIL, CUSTOMERNAME } from 'src/app/shared/session-variables';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  customerUuid: any;
  loginForm: FormGroup
  passwordVisibility: boolean = false
  validateCaptch:boolean=false;
  captchaCode: string;
  failed_count=0;
  show = false;
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private toaster: ToastrService,
    private sessionStorage: SessionService,
    private authService: AuthenticationService,
    private spinner: NgxSpinnerService,
    private commmonService:CommonService,
    private router: Router
  ) {

  }

  ngOnInit(): void {

    this.loginForm = this.fb.group({
      // email: ['', [Validators.required, Validators.email,]],
      customerUniqueId: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      captchaText: [''],
    });
    this.customerUuid = this.sessionStorage.get(CUSTOMERUUVID)

  }
  get formControl() {
    return this.loginForm.controls
  }

  togglePasswordVisibility(){
    this.passwordVisibility = !this.passwordVisibility
  }

  async login() {
    try {
      this.spinner.show()
      this.submitted = true;
      let captchaValid=false;
      if(this.validateCaptch)
        {
        captchaValid=await this.authService.validateCaptcha(this.captchaCode,this.loginForm);
        console.log(captchaValid)
        if(!captchaValid)
        {
          this.captchaCode =await this.authService.generateCaptcha(5); // Generate a new captcha
          this.toaster.error("Invalid captcha. Please try again.");
          return
        }
        }
      if (this.loginForm.invalid) {
        this.toaster.error("Enter Valid Customer Id Or Password");
        return
      }
      let params = {
        // "emailId": this.loginForm.value.email,
        "customerUserId": this.loginForm.value.customerUniqueId,
        "password": this.loginForm.value.password,
        "role":'customer'
        // "customerUuid": this.customerUuid
      }
      const cipherText=this.authService.encryptUsingAES256(JSON.stringify(params));

      let response = await this.authService.signIn({credentials:cipherText });

      if(response?.statusCode == 201){
        this.failed_count=0;
        this.toaster.success("Logged in Successfully");
        this.sessionStorage.set(CUSTOMERUSERID,this.loginForm.value.customerUniqueId)
        this.sessionStorage.set(TOKEN,response.response.token.token)
        this.sessionStorage.set(EMAIL, response.response.email)
        this.sessionStorage.set(CUSTOMERNAME, response.response.customerName)

        // this.commmonService.getCustomerLastWorkflow(this.customerUuid);
        this.router.navigateByUrl('/otp')
      }
    }
    catch (error) {
      if (error.response.data["statusCode"] == 403) {
        this.toaster.warning("Invalid user credentials")
        this.failed_count+=1;
        if(this.failed_count>=3)
        {
          this.validateCaptch=true;
          this.captchaCode =await this.authService.generateCaptcha(5);

        }
      }
    } finally {
      this.spinner.hide()
    }

  }



}
